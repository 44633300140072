import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import Link from '@mui/material/Link';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import SidebarMesages from '~/components/PersonalCoverage/SidebarMesages';
import rollbar from '../../rollbar';

import { Close } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  Fade,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Typography,
  useMediaQuery
} from '@mui/material';
import axios from 'axios';
import { Focused } from 'react-credit-cards-2';
import Header from '~/components/Header';
import Input from '~/components/Input';
import api from '~/services/api';
import useStore, { storeState } from '~/store';
import { titleStyle as ModalTitleStyle } from '../../modals/ModalHelpFinancialCharges/styles';
import CardComponent from './CardCredit';
import {
  container,
  containerButton,
  containerForm,
  containerTermOfContract,
  headerContainer,
  modalCard,
  modalCardContainer,
  modalCardHeader,
  modalContainer,
  modalContainerTitle,
  modalText,
  termOfContract,
  termOfContractLabel
} from './styles';
import { termOfContractData } from './utils/termOfContract';
import schema from './validation';

interface CreateTokenCardResponse {
  id: string;
  type: string;
  created_at: string;
  expires_at: string;
  card: {
    first_six_digits: string;
    last_four_digits: string;
    holder_name: string;
    exp_month: number;
    exp_year: number;
    brand: string;
  };
}

export type Inputs = {
  number: string;
  cvv: string;
  expiry: string;
  name: string;
  zipcode: string;
  state: string;
  city: string;
  neighborhood: string;
  street: string;
  numberAddress: string;
  additionalDetails: string;
};

const PAGARME_URL = process.env.REACT_APP_PAGARME_API_URL;
const PAGARME_APP_ID = process.env.REACT_APP_PAGARME_APP_ID;

export default function HelpZipcodePage() {
  const navigate = useNavigate();
  const showCard = useMediaQuery('(min-width:420px)');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [allIsEmpty, setAllIsEmpty] = useState(true);

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, dirtyFields }
  } = useForm<Inputs>({
    resolver: yupResolver(schema)
  });
  const [loading, setLoading] = useState(false);
  const {
    equipment,
    quoteResponse,
    quoteAsyncPostPayment,
    quotes,
    setZipcode,
    setState,
    setCity,
    setNeighborhood,
    setStreet,
    setNumber,
    setAdditionalDetails
  } = useStore((store) => ({
    quotes: store.quotes,
    equipment: store.quote.equipment,
    quoteResponse: store.quoteResponse,
    quoteAsyncPostPayment: store.quoteAsyncPostPayment,
    equipmentValue: store.quote.equipmentValue,
    setZipcode: store.setZipcode,
    setState: store.setState,
    setCity: store.setCity,
    setNeighborhood: store.setNeighborhood,
    setStreet: store.setStreet,
    setNumber: store.setNumber,
    setAdditionalDetails: store.setAdditionalDetails
    // products: store.quote.products
  }));
  useEffect(() => {
    if (quotes.length === 0) {
      navigate('/?modal=true');
    }
  }, []);

  const watchedFields = watch();
  useEffect(() => {
    const isEmpty: boolean =
      !watchedFields.number ||
      !watchedFields.cvv ||
      !watchedFields.expiry ||
      !watchedFields.name ||
      !watchedFields.zipcode ||
      !watchedFields.state ||
      !watchedFields.city ||
      !watchedFields.neighborhood ||
      !watchedFields.street ||
      !watchedFields.numberAddress;

    setAllIsEmpty(!isEmpty);
  }, [watchedFields, errors]);

  const zipcode = watch('zipcode');

  const [paymentError, setPaymentError] = useState<boolean | string>(false);
  const [focused, setfocused] = useState<Focused>('number');
  const [checkedTermOfContract, setCheckedTermOfContract] = useState<boolean>(false);
  useEffect(() => {
    if (!equipment) {
      navigate('/?modal=true');
    }
  }, []);

  useEffect(() => {
    const cleanZipcode = zipcode?.replace('-', '').trim();

    if (cleanZipcode && cleanZipcode.length === 8) {
      api.get(`ZipCodes?zipcode=${cleanZipcode}`).then(({ data: { data } }) => {
        Object.entries(data).forEach(([key, value]) => setValue(key as keyof Inputs, value as string));
      });
    }
  }, [zipcode]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    // const card = {
    //   card_number: data.number,
    //   card_holder_name: data.name,
    //   card_expiration_date: data.expiry,
    //   card_cvv: data.cvv
    // }

    // alert(process.env.REACT_APP_PAGARME_PUBLIC_KEY)
    // await pagarme.client
    //   .connect({ encryption_key: 'pk_test_JV0RYLQSRt3z9n81' }) // process.env.REACT_APP_PAGARME_PUBLIC_KEY })
    //   .then((client: any) => client?.security?.encrypt(card))
    //   .then((card_hash: string) => {
    //     quoteAsyncPostPayment({
    //       quote_id: quoteResponse?.quote_id,
    //       card_hash,
    //       tax_address: {
    //         additional_details: data.additionalDetails,
    //         number: data.numberAddress,
    //         zipcode: '54792495'
    //       }
    //     }).then(() => {
    //       setLoading(false)
    //       navigate('/paymentConfirm')
    //     })
    //   })
    //   .catch(() => {
    //     setLoading(false)
    //     setPaymentError('Houve um erro no pagamento. verifique os dados.')
    //   })

    const expiryMonth = data.expiry.split('/')[0];
    const expiryYear = data.expiry.split('/')[1];

    const createTokenCardPayload = {
      type: 'card',
      card: {
        number: data.number.replaceAll(' ', ''),
        exp_month: parseInt(expiryMonth),
        exp_year: parseInt(expiryYear),
        holder_name: data.name,
        cvv: data.cvv.trimEnd()
      }
    };

    try {
      setLoading(true);
      const createTokenCardResponse = await axios.post<CreateTokenCardResponse>(
        `${PAGARME_URL}/tokens?appId=${PAGARME_APP_ID}`,
        {
          ...createTokenCardPayload
        }
      );

      await quoteAsyncPostPayment({
        quote_id: quoteResponse?.quote_id,
        card_hash: createTokenCardResponse.data.id,
        tax_address: {
          additional_details: data.additionalDetails,
          number: data.numberAddress,
          zipcode: data.zipcode.replace('-', ''),
          city: data.city,
          state: data.state,
          neighborhood: data.neighborhood,
          street: data.street
        }
      });
      setLoading(false);
      navigate('/paymentConfirm');
    } catch (error: any) {
      setLoading(false);

      rollbar.error('Erro ao efetuar pagamento', error);

      let errorMessage = 'Não foi possível processar os seus dados no momento. Por favor, tente novamente mais tarde.';
      if (error.isAxiosError) {
        const notifications = error.response?.data?.notifications;
        if (notifications) errorMessage = notifications[0]?.message;
      }
      storeState.addToast({
        title: 'Error',
        message: errorMessage,
        type: 'error'
      });
    }

    setZipcode(data.zipcode);
    setState(data.state);
    setCity(data.city);
    setNeighborhood(data.neighborhood);
    setStreet(data.street);
    setNumber(data.numberAddress);
    setAdditionalDetails(data.additionalDetails);
  };

  return (
    <Container maxWidth="lg" sx={container}>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <Card>
            <CardContent>
              <Header title="Hora do pagamento!" goBackTo="/coverageFinish" />
              {showCard && (
                <Box sx={headerContainer}>
                  <CardComponent control={control} focused={focused as Focused} />
                </Box>
              )}
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={containerForm}>
                  <Grid container spacing={{ sm: 1 }}>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="number"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Input
                              label="Número do Cartão"
                              onFocus={(e) => setfocused('number')}
                              placeholder="XXXX XXXX XXXX XXXX"
                              mask={'cardNumber'}
                              isDirty={dirtyFields.number}
                              error={errors?.number?.message ? true : false}
                              helperText={errors?.number?.message}
                              {...field}
                            />
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="cvv"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Input
                              onFocus={(e) => setfocused('cvc')}
                              label="CVV"
                              placeholder="XXX"
                              mask={'cvv'}
                              isDirty={dirtyFields.cvv}
                              error={errors?.cvv?.message ? true : false}
                              helperText={errors?.cvv?.message}
                              // onBlur={() => setfocused('cvc')}
                              {...field}
                            />
                          </>
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Input
                          label="Nome no Cartão"
                          onFocus={(e) => setfocused('number')}
                          placeholder="exatamente como está impresso"
                          isDirty={dirtyFields.name}
                          error={errors?.name?.message ? true : false}
                          helperText={errors?.name?.message}
                          {...field}
                        />
                      </>
                    )}
                  />
                  <Grid container spacing={1}>
                    <Grid item xs={6} sm={6}>
                      <Controller
                        name="expiry"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Input
                              label="Expira"
                              onFocus={(e) => setfocused('number')}
                              placeholder="MM/AA"
                              mask={'expiredDate'}
                              error={errors?.expiry?.message ? true : false}
                              isDirty={dirtyFields.expiry}
                              helperText={errors?.expiry?.message}
                              {...field}
                            />
                          </>
                        )}
                      />
                    </Grid>
                  </Grid>

                  {/* DAQUI PARA BAIXO É ENDEREÇO */}

                  <Grid>
                    <Typography
                      variant="h3"
                      sx={{
                        fontSize: '20px',
                        marginTop: '32px',
                        fontWeight: 'bold'
                      }}
                    >
                      Endereço fiscal
                    </Typography>

                    <Grid item xs={12} sm={12}>
                      <Controller
                        name="zipcode"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Input
                              label="CEP"
                              placeholder="XXXXX-XXX"
                              mask={'zipcode'}
                              isDirty={dirtyFields.zipcode}
                              error={errors?.zipcode?.message ? true : false}
                              helperText={errors?.zipcode?.message}
                              {...field}
                            />
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Controller
                        name="state"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Input
                              label="Estado/UF (sigla)"
                              placeholder="Rio de Janiero"
                              inputProps={{ maxLength: 2 }}
                              isDirty={dirtyFields.state}
                              error={errors?.state?.message ? true : false}
                              helperText={errors?.state?.message}
                              {...field}
                            />
                          </>
                        )}
                      />
                    </Grid>

                    {/** CIDADE */}

                    <Grid item xs={12} sm={12}>
                      <Controller
                        name="city"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Input
                              label="Cidade"
                              placeholder="Rio de Janeiro"
                              isDirty={dirtyFields.city}
                              error={errors?.city?.message ? true : false}
                              helperText={errors?.city?.message}
                              {...field}
                            />
                          </>
                        )}
                      />
                    </Grid>

                    {/** BAIRRO */}

                    <Grid item xs={12} sm={12}>
                      <Controller
                        name="neighborhood"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Input
                              label="Bairro"
                              placeholder="Seu bairro"
                              isDirty={dirtyFields.neighborhood}
                              error={errors?.neighborhood?.message ? true : false}
                              helperText={errors?.neighborhood?.message}
                              {...field}
                            />
                          </>
                        )}
                      />
                    </Grid>

                    {/** RUA */}

                    <Grid item xs={12} sm={12}>
                      <Controller
                        name="street"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Input
                              label="Rua"
                              placeholder="Rua das Fontes"
                              isDirty={dirtyFields.street}
                              error={errors?.street?.message ? true : false}
                              helperText={errors?.street?.message}
                              {...field}
                            />
                          </>
                        )}
                      />
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name="numberAddress"
                          control={control}
                          render={({ field }) => (
                            <>
                              <Input
                                label={'Número'}
                                onFocus={(e) => setfocused('number')}
                                placeholder="XXX"
                                isDirty={dirtyFields.numberAddress}
                                error={errors?.numberAddress?.message ? true : false}
                                helperText={errors?.numberAddress?.message}
                                {...field}
                              />
                            </>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name="additionalDetails"
                          control={control}
                          render={({ field }) => (
                            <>
                              <Input
                                label={'Complemento'}
                                placeholder="Bloco, apartamento..."
                                isDirty={dirtyFields.additionalDetails}
                                error={errors?.additionalDetails?.message ? true : false}
                                helperText={errors?.additionalDetails?.message}
                                {...field}
                              />
                            </>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={containerTermOfContract}>
                  <FormControlLabel
                    sx={{ marginRight: 0.4 }}
                    control={
                      <Checkbox
                        checked={checkedTermOfContract}
                        onChange={() =>
                          setCheckedTermOfContract((prevValue) => {
                            return !prevValue;
                          })
                        }
                      />
                    }
                    label={
                      <>
                        <Typography sx={termOfContractLabel}>Li e concordo com os </Typography>
                        <Link sx={termOfContract} underline="hover" onClick={handleOpen}>
                          termos de uso
                        </Link>
                      </>
                    }
                  />
                  <Modal open={open} onClose={handleClose}>
                    <Fade in={open}>
                      <Box sx={modalContainer}>
                        <Box sx={modalContainerTitle}>
                          <Typography variant="h5" color="primary" sx={ModalTitleStyle}>
                            {termOfContractData.titulo.title}
                          </Typography>
                          <IconButton
                            color="default"
                            aria-label="upload picture"
                            component="span"
                            onClick={handleClose}
                          >
                            <Close />
                          </IconButton>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: 0,
                            marginBottom: 2.5
                          }}
                        >
                          {termOfContractData.titulo.data.map((item, index) => (
                            <Typography key={index} variant="body1" sx={modalText} paragraph>
                              {item}
                            </Typography>
                          ))}
                        </Box>
                        <Box sx={modalCardContainer}>
                          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 5 }}>
                            <Card sx={modalCard}>
                              <CardHeader
                                titleTypographyProps={{ textAlign: 'center', color: '#03DAC5', fontWeight: 'bold' }}
                                sx={modalCardHeader}
                                title={termOfContractData['termos de uso'].title.toUpperCase()}
                              />
                              <CardContent>
                                {termOfContractData['termos de uso'].data.map((item, index) => (
                                  <Typography
                                    variant="body1"
                                    sx={modalText}
                                    paragraph
                                    key={index}
                                    dangerouslySetInnerHTML={{ __html: item }}
                                  />
                                ))}
                                <Typography variant="h6" paragraph color="error" sx={modalText}>
                                  {termOfContractData['termos de uso'].disclaimer.title}
                                </Typography>
                                {termOfContractData['termos de uso'].disclaimer.data.map((item, index) => (
                                  <Typography variant="body1" sx={modalText} paragraph key={index}>
                                    {item}
                                  </Typography>
                                ))}
                              </CardContent>
                            </Card>
                            <Card sx={modalCard}>
                              <CardHeader
                                title={termOfContractData['disposições gerais'].title.toUpperCase()}
                                titleTypographyProps={{ textAlign: 'center', color: '#03DAC5', fontWeight: 'bold' }}
                                sx={modalCardHeader}
                              />
                              <CardContent>
                                {termOfContractData['disposições gerais'].data.map((item, index) => (
                                  <Typography
                                    variant="body1"
                                    paragraph
                                    key={index}
                                    sx={modalText}
                                    dangerouslySetInnerHTML={{ __html: item }}
                                  />
                                ))}
                              </CardContent>
                            </Card>
                            <Card sx={modalCard}>
                              <CardHeader
                                titleTypographyProps={{ textAlign: 'center', color: '#03DAC5', fontWeight: 'bold' }}
                                sx={modalCardHeader}
                                title={termOfContractData['politica de preços'].title.toUpperCase()}
                              />
                              <CardContent>
                                {termOfContractData['politica de preços'].data.map((item, index) => (
                                  <Typography
                                    variant="body1"
                                    paragraph
                                    sx={modalText}
                                    key={index}
                                    dangerouslySetInnerHTML={{ __html: item }}
                                  />
                                ))}
                              </CardContent>
                            </Card>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 2 }}>
                            <Card sx={modalCard}>
                              <CardHeader
                                titleTypographyProps={{ textAlign: 'center', color: '#03DAC5', fontWeight: 'bold' }}
                                sx={modalCardHeader}
                                title={termOfContractData['uso do website'].title.toUpperCase()}
                              />
                              <CardContent>
                                {termOfContractData['uso do website'].data.map((item, index) => (
                                  <Typography
                                    variant="body1"
                                    paragraph
                                    key={index}
                                    sx={modalText}
                                    dangerouslySetInnerHTML={{ __html: item }}
                                  />
                                ))}
                              </CardContent>
                            </Card>
                            <Card sx={modalCard}>
                              <CardHeader
                                titleTypographyProps={{ textAlign: 'center', color: '#03DAC5', fontWeight: 'bold' }}
                                sx={modalCardHeader}
                                title={termOfContractData['politica de privacidade'].title.toUpperCase()}
                              />
                              <CardContent>
                                <Typography
                                  variant="body1"
                                  paragraph
                                  sx={modalText}
                                  dangerouslySetInnerHTML={{
                                    __html: termOfContractData['politica de privacidade'].data[0]
                                  }}
                                />
                                <Typography
                                  variant="h6"
                                  paragraph
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      termOfContractData['politica de privacidade']['atualizacao desses termos'].title
                                  }}
                                />
                                {termOfContractData['politica de privacidade']['atualizacao desses termos'].data.map(
                                  (item, index) => (
                                    <Typography
                                      variant="body1"
                                      paragraph
                                      sx={modalText}
                                      key={index}
                                      dangerouslySetInnerHTML={{ __html: item }}
                                    />
                                  )
                                )}
                              </CardContent>
                            </Card>
                          </Box>
                        </Box>
                        <Box>
                          <Typography variant="body1" sx={{ marginBlock: 4 }}>
                            {termOfContractData.footer.data[0]}
                          </Typography>
                        </Box>
                      </Box>
                    </Fade>
                  </Modal>
                </Box>
                <Box>
                  <Typography>{paymentError} </Typography>
                </Box>
                <Box sx={containerButton}>
                  <LoadingButton
                    variant="contained"
                    disabled={!checkedTermOfContract || !allIsEmpty}
                    loading={loading}
                    type="submit"
                  >
                    Quero contratar
                  </LoadingButton>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          md={4}
          sx={{
            display: {
              xs: 'none',
              lg: 'block'
            }
          }}
        >
          <Card>
            <CardContent>
              <SidebarMesages showProduct={true} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
