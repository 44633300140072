import { QuoteRequestParams, CustomerRequestParams, QuoteProductsRequestParams, PaymentRequestParams } from './types';
import api from '~/services/api';
import { QUOTE_RESPONSE, CONTRACT_RESPONSE, QUOTE_PRODUCTS } from '../../variables';

class QuoteService {
  async postQuote(payload: QuoteRequestParams) {
    console.log('QuoteService', payload);
    const {
      data: { data }
    } = await api.post('/quotes', payload);

    console.log('QuoteService data', data);
    localStorage.setItem(QUOTE_RESPONSE, JSON.stringify(data));
    return data;
  }
  localQuote() {
    const userDataString = localStorage.getItem(QUOTE_RESPONSE);
    const userData = userDataString && JSON.parse(userDataString);
    const quotes = userData?.quotes;
    delete userData?.quotes;
    const CONTRACT_RESPONSEString = localStorage.getItem(CONTRACT_RESPONSE);
    const contractResponse = CONTRACT_RESPONSEString && JSON.parse(CONTRACT_RESPONSEString);
    return { quotes, quoteResponse: userData, contractResponse };
  }
  async postQuoteCustomer(quoteId: string, payload: CustomerRequestParams) {
    const { data } = await api.put(`/quotes/${quoteId}/customer`, payload);
    return data;
  }
  async postQuotePayment(payment: PaymentRequestParams) {
    const {
      data: { data }
    } = await api.post(`/Contracts`, payment);
    localStorage.setItem(CONTRACT_RESPONSE, data.contract_id);
    return data;
  }
  async updateQuote(quoteId: string, payload: QuoteProductsRequestParams) {
    const { data } = await api.put(`/quotes/${quoteId}`, payload);
    localStorage.setItem(QUOTE_PRODUCTS, JSON.stringify(data));
    return data;
  }
}

export default new QuoteService();
