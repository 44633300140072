import { useEffect, useState } from 'react';
import SidebarMesages from '~/components/PersonalCoverage/SidebarMesages';
import { Container, Card, Grid, Box, CardContent, Typography, Button, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as styles from './styles';
import InputFile from '~/components/InputFile';
import useStore, { storeState } from '~/store';
import { Message } from '~/components/PersonalCoverage/SidebarMesages/types';
import { useNavigate } from 'react-router-dom';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { forEach, forIn } from 'lodash';
import { IFile } from '~/services/modules/contract/types';
import { VoucherError } from './VoucherError';
import { invoiceSchema, schema } from './validation';
import { ValidationError } from 'yup';

type TypeFile = 'invoice' | 'overview' | 'serial_number_photo' | 'ratchets_group';

interface FilesError {
  invoice: boolean;
  serial_number_photo: boolean;
  overview: boolean;
  ratchets_group: boolean;
}

export default function FileComponent() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [hasVoucherError, setHasVoucherError] = useState(false);
  const [state, setState] = useState<{
    invoice: IFile | null;
    serial_number_photo: IFile | null;
    overview: IFile | null;
    ratchets_group: IFile | null;
  }>({
    invoice: null,
    overview: null,
    serial_number_photo: null,
    ratchets_group: null
  });

  const [errors, setErrors] = useState({} as FilesError);

  const {
    contract_id,
    contractAsyncPostFiles,
    contractAsyncGetVoucher,
    voucher,
    quoteHasInvoice,
    insuredHasInvoice,
    isNew
  } = useStore((store) => ({
    contract_id: store.contract_id,
    contractAsyncPostFiles: store.contractAsyncPostFiles,
    contractAsyncGetVoucher: store.contractAsyncGetVoucher,
    voucher: store.voucher,
    quoteHasInvoice: store.quote.hasInvoice,
    insuredHasInvoice: store.insured_item.has_invoice,
    isNew: store.insured_item.is_new
  }));

  const hasInvoice = insuredHasInvoice || quoteHasInvoice;

  const onChangeFile = (key: TypeFile, value: IFile) => {
    setState((state) => ({
      ...state,
      [key]: value
    }));
    setErrors((state) => ({ ...state, [key]: false }));
  };

  const onSubmit = async () => {
    if (contract_id) {
      try {
        setLoading(true);

        await contractAsyncPostFiles(contract_id, state);
        setLoading(false);
        navigate('/contract/emergency');
      } catch (error: any) {
        setLoading(false);
        if (error instanceof ValidationError) {
          const fields: TypeFile[] = ['serial_number_photo', 'overview', 'ratchets_group', 'invoice'];
          const fieldsError = error.errors;
          const errors = {} as FilesError;

          fields.forEach((field) => {
            const hasError = fieldsError.includes(field);
            if (hasError) {
              errors[field] = true;
            } else {
              errors[field] = false;
            }
          });

          setErrors(errors);
        } else {
          let errorMessage = 'Não foi possível processar os arquivos. Por favor, tente novamente mais tarde.';
          if (error.isAxiosError) {
            const notifications = error.response?.data?.notifications;
            if (notifications) errorMessage = notifications[0]?.message;
          }
          storeState.addToast({
            title: 'Error',
            message: errorMessage,
            type: 'error'
          });
        }
      }
    } else {
      storeState.addToast({
        title: 'Não foi possível processar os arquivos',
        message: 'Nenhum contrato foi encontrado',
        type: 'warning'
      });
    }
  };

  const renderUploads = () => {
    if (hasInvoice && isNew) {
      return (
        <Grid item xs={12} sm={6} display={'flex'} alignItems={'flex-end'}>
          <InputFile
            error={errors?.invoice}
            label="Nota Fiscal"
            onChangeFile={(file) => onChangeFile('invoice', file)}
          />
        </Grid>
      );
    }

    if (hasInvoice && !isNew) {
      return (
        <>
          <Grid item xs={12} sm={6} display={'flex'} alignItems={'flex-end'}>
            <InputFile
              error={errors?.invoice}
              label="Nota Fiscal"
              onChangeFile={(file) => onChangeFile('invoice', file)}
            />
          </Grid>
          <Grid item xs={12} sm={6} display={'flex'} alignItems={'flex-end'}>
            <InputFile
              error={errors?.overview}
              label="Equipamento inteiro"
              onChangeFile={(file) => onChangeFile('overview', file)}
            />
          </Grid>
          <Grid item xs={12} sm={6} display={'flex'} alignItems={'flex-end'}>
            <InputFile
              error={errors?.serial_number_photo}
              label="Número de série"
              onChangeFile={(file) => onChangeFile('serial_number_photo', file)}
            />
          </Grid>
          <Grid item xs={12} sm={6} display={'flex'} alignItems={'flex-end'}>
            <InputFile
              error={errors?.ratchets_group}
              label="Grupo de catracas"
              onChangeFile={(file) => onChangeFile('ratchets_group', file)}
            />
          </Grid>
        </>
      );
    }

    if (!hasInvoice) {
      return (
        <>
          <Grid item xs={12} sm={6} display={'flex'} alignItems={'flex-end'}>
            <InputFile
              error={errors?.overview}
              label="Equipamento inteiro"
              onChangeFile={(file) => onChangeFile('overview', file)}
            />
          </Grid>
          <Grid item xs={12} sm={6} display={'flex'} alignItems={'flex-end'}>
            <InputFile
              error={errors?.serial_number_photo}
              label="Número de série"
              onChangeFile={(file) => onChangeFile('serial_number_photo', file)}
            />
          </Grid>
          <Grid item xs={12} sm={6} display={'flex'} alignItems={'flex-end'}>
            <InputFile
              error={errors?.ratchets_group}
              label="Grupo de catracas"
              onChangeFile={(file) => onChangeFile('ratchets_group', file)}
            />
          </Grid>
        </>
      );
    }
  };

  const renderContent = (hasInvoice: boolean) => {
    return (
      <Card>
        <CardContent>
          <Box sx={styles.headerContainer}>
            <Typography variant="h6" align="center" fontWeight="bold">
              Fotos para contrato
            </Typography>
          </Box>
          <Divider variant="middle" />
          <Box sx={styles.containerForm}>
            <Typography sx={{ fontSize: { xs: 16, sm: 20 } }} variant="h6" fontWeight="bold">
              4 - Fotos do Equipamento
            </Typography>
            <Typography sx={styles.descritionTitle}>
              *Atenção! É muito importante observar a qualidade das fotografias e a nitidez dos documentos
              digitalizados. A baixa qualidade dos documentos anexados poderá implicar na recusa por parte da seguradora
              da aceitação do seu seguro. Não deixe que isso aconteça
            </Typography>
            {!hasInvoice && (
              <Typography>
                <Typography sx={styles.warningText} component={'span'}>
                  IMPORTANTE:{' '}
                </Typography>
                O voucher{' '}
                <Typography sx={styles.voucherStyle} component={'span'}>
                  {voucher}
                </Typography>{' '}
                é necessário enviar junto a foto da visão geral escrito em um papel fixado no equipamento.
              </Typography>
            )}
          </Box>
          <Grid container spacing={2}>
            {renderUploads()}
          </Grid>
          <Box sx={styles.containerButton}>
            <LoadingButton sx={{ fontSize: { xs: 16, sm: 20 } }} loading={loading} onClick={onSubmit}>
              Salvar e sair
            </LoadingButton>
            <LoadingButton
              sx={{ fontSize: { xs: 16, sm: 20 } }}
              loading={loading}
              variant="contained"
              onClick={onSubmit}
            >
              Próximo passo
            </LoadingButton>
          </Box>
        </CardContent>
      </Card>
    );
  };

  useEffect(() => {
    async function fetchVoucherData(contractId: number) {
      setLoading(true);
      try {
        await contractAsyncGetVoucher(contractId);
        setLoading(false);
      } catch (error) {
        setHasVoucherError(true);
        setLoading(false);
      }
    }

    if (!hasInvoice) {
      fetchVoucherData((contract_id as unknown) as number);
    }
  }, []);

  if (hasVoucherError) {
    return <VoucherError />;
  }

  return (
    <Container maxWidth="lg" sx={styles.container}>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          {renderContent(hasInvoice)}
        </Grid>
        <Grid item md={4} xs={12}>
          <Card>
            <CardContent>
              <SidebarMesages showProduct={true} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
