import { GetState, SetState } from 'zustand';
// import { i18n } from '~/i18n/i18n'
// import { storeState } from '~/store'
import { ContractStore, Insured, InsuredItem, Plan, UserForm } from './types';
// import axios, { AxiosError } from 'axios'
import ContractService from '~/services/modules/contract';
import {
  ContractPostAddress,
  ContractPostCustomer,
  ContractPostEmergency,
  ContractPostEquipment,
  ContractPostFiles
} from '~/services/modules/contract/types';

const createQuoteSlice = (set: SetState<ContractStore>, get: GetState<ContractStore>) => ({
  userForm: {},
  step: 'none',
  contract_id: null,
  cameFromEmail: false,
  manufacturers: [],
  voucher: '',
  insured: {} as Insured,
  insured_item: {} as InsuredItem,
  plan: {} as Plan,
  flat_rate: 0,
  has_club: false,
  club_name: '',
  club_description: '',
  setUserForm: (user: UserForm) => {
    set({ user: user });
  },
  setContractId: (contract_id: number) => {
    set({ contract_id: contract_id });
  },
  setCameFromEmail: (cameFromEmail: boolean) => {
    set({ cameFromEmail });
  },
  contractAsyncGetContext: async (contract_id: number, callback?: () => void) => {
    const contractContext = await ContractService.getContext(contract_id);
    const { step } = contractContext;
    set({ step, contract_id });
    callback && callback();
  },
  contractAsyncGetContractById: async (contract_id: number, callback?: () => void) => {
    const response = await ContractService.getContractById(contract_id);

    const {
      insured,
      insured_item,
      total: totalValue,
      financial_charges: serviceCharge,
      plan,
      flat_rate,
      plan: { has_club, club_name, club_description }
    } = response;

    set({
      insured,
      insured_item,
      totalValue,
      serviceCharge,
      plan,
      flat_rate,
      has_club,
      club_name,
      club_description
    });
    callback && callback();
  },
  contractAsyncPostCustomer: async (contract_id: string, body: ContractPostCustomer, callback?: () => void) => {
    // try {
    await ContractService.postCustomer(contract_id, body);
    callback && callback();
    // } catch (error) {
    //   // set({ quote: null })
    // }
  },
  contractAsyncPostAddress: async (contract_id: string, body: ContractPostAddress, callback?: () => void) => {
    await ContractService.postInsuredItemAddress(contract_id, body);
    callback && callback();
  },
  contractAsyncGetManufacturer: async (callback?: () => void) => {
    try {
      const data = await ContractService.getManufacturers();
      set({ manufacturers: data });
      callback && callback();
    } catch (error) {
      // set({ quote: null })
    }
  },
  contractAsyncPutEquipment: async (contract_id: number, body: ContractPostEquipment, callback?: () => void) => {
    await ContractService.putInsuredItem(contract_id, body);
    callback && callback();
  },
  contractAsyncPostEmergency: async (contract_id: number, body: ContractPostEmergency, callback?: () => void) => {
    await ContractService.postEmergency(contract_id, body);
    callback && callback();
  },
  contractAsyncDownload: async (contract_id: string, callback?: () => void) => {
    const response = await ContractService.getDownload(contract_id);

    const link = document.createElement('a');
    link.href = `data:application/pdf;base64,${response.data}`;
    link.download = 'contrato-aride.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    callback && callback();
  },
  contractAsyncPostFiles: async (contract_id: string, body: ContractPostFiles, callback?: () => void) => {
    await ContractService.postFiles(contract_id, body);
    callback && callback();
  },
  contractAsyncGetVoucher: async (contract_id: number, callback?: () => void) => {
    const { data } = await ContractService.getVoucher(contract_id);
    set({ voucher: data.voucher });
    callback && callback();
  }
});

export default createQuoteSlice;
