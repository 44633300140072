import { Message } from '~/components/Chat/types';
import {
  cpfMessages,
  emailMessages,
  equipmentMessages,
  genderMessages,
  nameMessages,
  nextAcessoriesValueMessages,
  nextCompetionsMessages,
  nextEquipmentMaterialMessages,
  nextEquipmentValueMessages,
  nextInternationalCoverageMessages
} from './handleMessages';

interface IHandleStepsProps {
  setDisableStepHandler: (value: boolean) => void;
  setMessages: (messages: any) => void;
  MESSAGES: Message[] | any[];
  setStepChat: (value: number) => void;
  removeMessage: (index: number) => void;
  internationalCoverage: boolean;
  equipment: string;
  name: string;
}

export interface IMessage extends Pick<IHandleStepsProps, 'MESSAGES' | 'setMessages' | 'setDisableStepHandler'> {}

interface IHandleNextStepProps {
  currentStep: number;
  steps?: number[];
  setStepChat: (value: number) => void;
}

export const handleNextStep = ({ setStepChat, currentStep, steps = [] }: IHandleNextStepProps) => {
  if (currentStep === steps[steps.length - 1]) {
    setStepChat(0);
  }

  const nextStepChat = steps.find((num: number) => num > currentStep) || 0;

  setStepChat(nextStepChat);
};

export const handlePreviousStep = (
  { setStepChat, currentStep, steps = [] }: IHandleNextStepProps,
  params: IMessage,
  removeMessage: (index: number) => void,
  equipment: string,
  name: string,
  internationalCoverage: boolean
) => {
  const previousStepChat = steps.find((num: number) => currentStep === num) || 0;

  const stepsHandlers: { [key: number]: () => void } = {
    1: () => nameMessages({ ...params }),
    2: () => {
      emailMessages({ ...params }, removeMessage, name);
      setStepChat(2);
    },
    3: () => {
      cpfMessages({ ...params }, removeMessage);
      setStepChat(3);
    },
    4: () => {
      genderMessages({ ...params }, removeMessage);
      setStepChat(4);
    },
    5: () => {
      equipmentMessages({ ...params }, removeMessage);
      setStepChat(5);
    },
    6: () => {
      nextEquipmentValueMessages({ ...params }, equipment);
      setStepChat(6);
    },
    7: () => {
      nextAcessoriesValueMessages({ ...params }, equipment);
      setStepChat(7);
    },
    8: () => {
      nextEquipmentMaterialMessages({ ...params });
      setStepChat(8);
    },
    9: () => {
      nextCompetionsMessages({ ...params }, name);
      setStepChat(9);
    },
    10: () => {
      nextInternationalCoverageMessages({ ...params }, name);
      setStepChat(10);
    }
  };

  stepsHandlers[previousStepChat - 1]();
};

export const handleSteps = ({
  setDisableStepHandler,
  setMessages,
  MESSAGES,
  setStepChat,
  removeMessage,
  internationalCoverage,
  equipment,
  name
}: IHandleStepsProps) => {
  const handleName = async () => nameMessages({ MESSAGES, setDisableStepHandler, setMessages });

  const handleNextEmail = async (currentStep: number, steps: number[]) => {
    handleNextStep({ setStepChat, currentStep, steps });

    emailMessages({ MESSAGES, setDisableStepHandler, setMessages }, removeMessage, name);
  };

  const handleNextCpf = async (currentStep: number, steps: number[]) => {
    handleNextStep({ setStepChat, currentStep, steps });

    cpfMessages({ MESSAGES, setDisableStepHandler, setMessages }, removeMessage);
  };

  const handleNextGender = async (currentStep: number, steps: number[]) => {
    handleNextStep({ setStepChat, currentStep, steps });

    genderMessages({ MESSAGES, setDisableStepHandler, setMessages }, removeMessage);
  };

  const handleNextEquipment = async (currentStep: number, steps: number[]) => {
    handleNextStep({ setStepChat, currentStep, steps });

    equipmentMessages({ MESSAGES, setDisableStepHandler, setMessages }, removeMessage);
  };

  const handleNextEquipmentValue = async (currentStep: number, steps: number[]) => {
    handleNextStep({ setStepChat, currentStep, steps });

    nextEquipmentValueMessages({ MESSAGES, setDisableStepHandler, setMessages }, equipment);
  };

  const handleNextAccessoriesValue = async (currentStep: number, steps: number[]) => {
    handleNextStep({ setStepChat, currentStep, steps });

    nextAcessoriesValueMessages({ MESSAGES, setDisableStepHandler, setMessages }, equipment);
  };

  const handleNextEquipmentMaterial = async (currentStep: number, steps: number[]) => {
    handleNextStep({ setStepChat, currentStep, steps });

    nextEquipmentMaterialMessages({ MESSAGES, setDisableStepHandler, setMessages });
  };

  const handleNextCompetitions = async (currentStep: number, steps: number[]) => {
    handleNextStep({ setStepChat, currentStep, steps });

    nextCompetionsMessages({ MESSAGES, setDisableStepHandler, setMessages }, name);
  };

  const handleNextInternationalCoverage = async (currentStep: number, steps: number[]) => {
    handleNextStep({ setStepChat, currentStep, steps });

    nextInternationalCoverageMessages({ MESSAGES, setDisableStepHandler, setMessages }, name);
  };

  return {
    handleName,
    handleNextEmail,
    handleNextCpf,
    handleNextGender,
    handleNextEquipment,
    handleNextEquipmentValue,
    handleNextAccessoriesValue,
    handleNextEquipmentMaterial,
    handleNextCompetitions,
    handleNextInternationalCoverage
  };
};
